body{
    padding-top: 100px;
}

.btn.btn-facebook{
    background-color: #3e5b98;
    color: white;
    width: 100%;
    margin-bottom: 10px;
}
.btn.btn-google{
    background-color: #dd4b39;
    color: white;
    width: 100%;
    margin-bottom: 10px;
}

.btn.btn-google span,
.btn.btn-facebook span{
    margin-right: 10px;
}

.footer{
    margin-top: 50px;
    margin-left: 30px;
    margin-right: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
}